.alert {
  background-color: rgba(0,0,0,.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999999;
}

.alert h2 {
  color: white;
  font-weight: bold;
  text-align: center;
  margin-top: 30px;
}

